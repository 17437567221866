import * as moment from "moment";

export const dayExistCheck = (element, index, errors) => {
    // Check whether selected day exist between start date and end date
    if (element.day.value && !element.periodical_start_date && !element.periodical_end_date) {
        errors[index] = "SELECTED_DAY_DOES_NOT_EXIST";
    }
    else {
        errors[index] = ""
    }
    return errors;
}

export const duplicateEntryCheckOnArray = (periodicMaintenanceList, errors, index) => {
    let arr = periodicMaintenanceList.slice();
    for (let i = 0; i < errors.length; i++) {
        if (errors[i] !== "SELECTED_DAY_DOES_NOT_EXIST") {
            errors[i] = '';
        }

    }
    // check whether there exist an entry with same day, start time and end time
    for (var i = 0; i < arr.length - 1; i++) {
        for (var j = 1; j < arr.length - 1; j++) {
            if (errors[i] === "SELECTED_DAY_DOES_NOT_EXIST") {
                continue
            }
            if (i === j || j < i) {
                continue;
            }
            if (JSON.stringify(arr[i]) === JSON.stringify(arr[j]) && i !== j) {
                if (i === index) {
                    errors[i] = "DUPLICATE_ENTRY_NOT_ALLOWED"
                } else {
                    errors[j] = 'DUPLICATE_ENTRY_NOT_ALLOWED';
                }
            }
        }
    };

    return errors;
}
export const isAfterTime = (periodicMaintenanceList, index, errors) => {
    let checkField = moment(periodicMaintenanceList[index].periodical_start_date.format('HH:mm'));
    let valueField = moment(periodicMaintenanceList[index].periodical_end_date.format('HH:mm'));
    if (valueField && valueField.diff(checkField, "seconds") <= 0) {
        errors[index] = 'GREATER_THAN_PERIOD_START_TIME';
    }
    return errors;
}
export const duplicateEntryCheck = (periodicMaintenanceList, newEntryIndex, errors) => {
    // check whether there exist an entry with same day, start time and end time
    for (var i = 0; i < periodicMaintenanceList.length; i++) {
        if (JSON.stringify(periodicMaintenanceList[i]) === JSON.stringify(periodicMaintenanceList[newEntryIndex]) && i !== newEntryIndex) {
            if (errors[i] === "DUPLICATE_ENTRY_NOT_ALLOWED") {
                errors[i] = '';
            }
            errors[newEntryIndex] = "DUPLICATE_ENTRY_NOT_ALLOWED";
            break;
        }
        else {
            errors[newEntryIndex] = "";
        }
    };
    return errors;
}

