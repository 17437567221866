import React, { Component } from 'react';
import * as openIdConfig from '../../constants/open-id-config';
import './login.scss'

export default class Login extends Component {
  
  componentDidMount() {
    if (!localStorage.hasOwnProperty('jwt_token')) {
      window.location = openIdConfig.authorisation_url;
  }
  else {
    this.props.history.push("/list");
  }
}
  render() {
    return (
     <div className="login-container"></div>
    )
  }
}
