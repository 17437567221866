import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody } from 'reactstrap';
import './warning-popup.scss';
import close from '../../assets/images/close.png'

export default class WarningPopup extends Component {
  render() {
    return (
      <div className={"warning" + this.props.class}>
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={"warning-popup-modal " + this.props.class} centered>
          <ModalBody>
          <div className="close-div" onClick={this.props.toggle}>
            <img className="close" src={close} alt="close"></img>
            </div>
              <div className="message-div">
              <p className="message"><FormattedMessage id={this.props.message}/></p>
              </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
