import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { withRouter } from "react-router";
import { Button, Popover, PopoverBody } from 'reactstrap';
import Duplicate from "./../../../assets/icons/Duplicate.svg";
import Edit from "./../../../assets/icons/Edit.svg";
import More from "./../../../assets/icons/More_button.svg";
import MoreClicked from "./../../../assets/icons/More_button_1.svg";
import './ActionsButton.scss';
import AlertDataContext from "../../../contexts/alert-data-context";
import * as CONSTANTS from './../../../constants/constants';
import EntitlementsContext from "../../../contexts/entitlements-context";
import { entitlements } from '../../../constants/entitlements';

class ActionsButton extends Component {
  constructor(props) {
    super(props);
    this.toggleActions = this.toggleActions.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);

    this.state = { popoverOpen: false };
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  toggleActions(event) {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  closePopup(event) {
    if ((this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) && (event.target.classList) && !(event.target.classList.contains('action') || (event.target.parentElement && event.target.parentElement.classList.contains('action')))) {
      this.setState({
        popoverOpen: false
      });
    }
  }
  gotoCreate(type, setData) {
    this.props.rowData.periodicalErrors = [""]
    setData(this.props.rowData)
    this.props.history.push(`/alert?${type}=${this.props.rowData.id}`);
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.closePopup);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closePopup);
  }

  render() {
    return (

      <AlertDataContext.Consumer>
        {({ setData }) => (
          <div>{this.context.entitlements.includes(entitlements['newAlert'])?<div className="popup-more" ref={this.wrapperRef}>
            <Button id={"Popover" + this.props.rowData.id} color="link" >
              <img src={this.state.popoverOpen ? MoreClicked : More} alt="Toggle actions menu" />
            </Button>
            <Popover trigger="click" placement="bottom" isOpen={this.state.popoverOpen} target={"Popover" + this.props.rowData.id} toggle={event => this.toggleActions(event)} hideArrow className="more-action">
              <PopoverBody>
                {!!!this.props.rowData.is_expired &&
                  <button className="btn btn-primary action edit text-capitalize" onClick={() => this.gotoCreate(CONSTANTS.MORE_ACTIONS.edit, setData)}>
                    <img src={Edit} alt="" className="pr-3" />
                    <FormattedMessage id="EDIT"
                      defaultMessage="edit" />
                  </button>
                }
                <button className="btn btn-primary action duplicate text-capitalize" onClick={() => this.gotoCreate(CONSTANTS.MORE_ACTIONS.duplicate, setData)}>
                  <img src={Duplicate} alt="" className="pr-3" />
                  <FormattedMessage id="DUPLICATE"
                    defaultMessage="duplicate" />
                </button>
              </PopoverBody>
            </Popover>
          </div>:''}
          </div>
        )}
      </AlertDataContext.Consumer>
    )
  }
}

export default withRouter(ActionsButton);
ActionsButton.contextType = EntitlementsContext;