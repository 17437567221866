import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next/lib/src/bootstrap-table';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import Search from "../../assets/icons/Search.svg";
import ApiPath from '../../constants/api-path';
import axiosInstance from '../../utlities/interceptor';
import Checkbox from './checkbox/checkbox';
import tableHeaderTranslation from './../List/table-functions';
import './systemFunctionMapping.scss';
import EntitlementsContext from '../../contexts/entitlements-context';
import { entitlements } from '../../constants/entitlements';

class systemFunctionMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappingList: [],
      filteredmappingList: [],
      systemList: [],
      isLoading: true,
      isDataSaved: false,
      columns: [],
      onLoad: true
    };
    this.getSystems = this.getSystems.bind(this);
    this.getMappings = this.getMappings.bind(this);
    this.generateColumns = this.generateColumns.bind(this);
    this.toggleSystems = this.toggleSystems.bind(this);
    this.updateSystems = this.updateSystems.bind(this);
    this.updateRowData = this.updateRowData.bind(this);
    this.searchInput = React.createRef();
  }
  componentDidMount() {
    this.getMappings();
    this.getSystems();
  }
  componentDidUpdate() {
    // only users with entitlement can edit the mapping
    if (this.context.entitlements && this.context.entitlements.includes(entitlements['componentsCreation']) && this.state.onLoad) {
      this.setState({enableDataEntry: true, onLoad: false})
    }
  }
  toggleSystems(checkboxValue, rowData, col) {
    this.setState({
      isSaving: true,
    });
    let originalRow = this.state.mappingList.find((item) => item.id === rowData.id);
    let systems = originalRow.systems;

    if (checkboxValue) {
      systems.push(col)
    } else {
      const result = systems.filter(system => col !== system);
      systems = [...result]
    }
    this.updateRowData(rowData.id, systems)
    this.updateSystems(rowData.id, systems)
  }

  updateSystems(id, systems) {
    axiosInstance.put(`${ApiPath.function_category_systems}${id}`, { systems: systems })
      .then(res => {
        this.setState({
          isSaving: false,
        });
      })
      .catch(error => {
        this.setState({
          isSaving: false,
        });
      });
  }

  updateRowData(id, systems) {
    let newMapping = this.state.mappingList;
    const index = this.state.mappingList.map(e => e.id).indexOf(id)
    const category = newMapping[index].category;
    const functionName = newMapping[index].function;
    const newRow = { id: id, category, function: functionName, systems }

    newRow.systems.forEach(system => {
      newRow[system] = true;
    });
    newMapping[index] = newRow
    this.setState({
      mappingList: newMapping,
    });
    this.search();
  }

  
  getSystems() {
    axiosInstance.get(ApiPath.system).then(res => {
      let systemList = res.data;
      systemList.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      this.setState({
        systemList: systemList,
      });
      this.generateColumns();
    });
  }

  getMappings() {
    axiosInstance.get(ApiPath.function_category_systems).then(res => {
      let mappingList = res.data;
      mappingList.sort(function (a, b) {
        return a.function.localeCompare(b.function);
      });
      mappingList.forEach(element => {
        element.systems.forEach(system => {
          element[system] = true;
        });
      });
      this.setState({
        mappingList: mappingList,
        filteredmappingList: mappingList,
        isLoading: false
      });
    });
  }
 
  generateColumns() {
    let columns = [{
      dataField: 'function',
      text: 'FUNCTION_CATEGORY',
      headerFormatter: (column, colIndex) => tableHeaderTranslation(column, colIndex),

    }, {
      dataField: 'category',
      text: 'CATEGORY',
      headerFormatter: (column, colIndex) => tableHeaderTranslation(column, colIndex),

    }];
    this.state.systemList.forEach(element => {
      columns.push({
        dataField: element.name,
        text: element.name,
        formatter: (cell, row) => {
          return (
            <Checkbox rowData={row} cellContent={cell} col={element.name} toggleSystems={this.toggleSystems} enableDataEntry={this.state.enableDataEntry}/>
          );
        }
      })
    });
    this.setState({
      columns: columns,
    });
  }

  search() {
    if (!this.searchInput.current) {
      return
    }
    if (this.searchInput.current.value.length === 0) {
      this.setState({
        filteredmappingList: this.state.mappingList,
        isLoading: false
      });
    }
    let value = (this.searchInput.current.value).toString().toUpperCase();
    const filteredItems = this.state.mappingList.filter(item => (item.category.includes(value) || item.function.includes(value)))

    this.setState({
      filteredmappingList: filteredItems,
      isLoading: false
    });
  }

  render() {
    /**IE fix for table */
    const DIV = styled.div`
      table {
        border-collapse: separate !important;
      }
    `;
    return (
      <div className="system-function-mapping  page-padding">
        <div className="mb-4 d-flex mt-4 w-100 px-3 flex-wrap heading-text-div">
          <span className="heading text-capitalize fs-16 page-header col-sm-12 col-md-7 col-lg-9 p-0">
            <FormattedMessage id="SYSTEM_FUNCTION_CATEGORY_MAPPING" />
          </span>
          <div className="input-group flex-nowrap col-10 col-sm-8 col-md-5 col-lg-3 p-0">
            <input type="text" className="form-control"
              placeholder={this.props.intl.formatMessage({ id: 'SEARCH' })}
              ref={this.searchInput}
              onChange={e => this.search(e.target.value)}
              aria-label="Search" aria-describedby="addon-wrapping" />
            <div className="input-group-append pointer" onClick={this.search}>
              <span className="input-group-text search-icon" id="addon-wrapping"><img src={Search} alt="" /></span>
            </div>
          </div>
        </div>
        <hr />
        {(!!this.state.columns.length && !!this.state.systemList.length) &&
          <DIV className="w-100 px-3">
            <BootstrapTable
              keyField="id"
              data={this.state.filteredmappingList}
              columns={this.state.columns}
              bordered={false}
              bootstrap4={true}
            />
          </DIV>
        }
        {((this.state.mappingList.length === 0 ) && !this.state.isLoading) &&
          <div className="text-center">
            <span className="load-more text-capitalize pointer btn text-center" >
              <FormattedMessage id="NO_DATA" />
            </span>
          </div>
        }
        {(this.state.filteredmappingList.length === 0 && this.state.mappingList.length !== 0 && !this.state.isLoading) &&
          <div className="text-center">
            <span className="load-more text-capitalize pointer btn text-center" >
              <FormattedMessage id="NO_SEARCH_DATA" />
            </span>
          </div>
        }
        {this.state.isLoading && (
          <div className="text-center">
            <span className="load-more text-capitalize pointer btn text-center">
              <FormattedMessage id="LOADING" />
              {this.state.isLoading && <div className="lds-dual-ring" />}
            </span>
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(systemFunctionMapping);
systemFunctionMapping.contextType = EntitlementsContext;