import React, { Component } from 'react';
import axiosInstance from "./../../utlities/interceptor";
import ApiPath from "./../../constants/api-path";
import { logout } from '../../utlities/logout';

export default class Home extends Component {
    componentDidMount() {
        const queryString = require('query-string');
        let c = queryString.parse(this.props.location.search).code;
        if (!c) {
            this.props.history.push("/logout");
        }
        if (!localStorage.hasOwnProperty('jwt_token') && c) {
            let config = { type: 'auth' }
            axiosInstance.post(ApiPath.openId, {}, config)
                .then(res => {
                    localStorage.setItem('jwt_token', res.data.id_token);
                    localStorage.setItem('access_token', res.data.access_token)
                    config = { type: 'userInfo' }
                    this.getUserInfo();
                })
                .catch(error => {
                    this.props.history.push("/login");
                });
        }
        else {
            this.props.history.push("/list");
        }
    }

    getUserInfo = (setData) => {
        if (localStorage.getItem('jwt_token')) {
            let config = { type: 'userInfo' }
            axiosInstance.get(`${ApiPath.userInfo}`, config).then(res => {
                this.getEntitlements(res.data.username.replace('Daimler_', ''))
            }).catch(error => {
                console.log(error)
            })
        }
    }
    getEntitlements = (userName) => {
        axiosInstance.get(`${ApiPath.authorization}${userName}`).then(res => {
            if (res.data.attrs.dcxIapAuthGrps.includes('WRMNSERV.StartAuthorization')) {
                console.log(res.data)
                console.log('home')
                this.props.history.push("/list");
            }
            else {
                alert('You are unauthorized')
                logout();
            }
        })
    }
    render() {
        return (
            <div>

            </div>
        )
    }
}
