import * as Yup from "yup";
import * as moment from "moment";

Yup.addMethod(Yup.string, "dateGreaterThanPublication", dateGreaterThanPublication);
Yup.addMethod(Yup.string, "futureDatesOnly", futureDatesOnly);
Yup.addMethod(Yup.string, "isAfterTime", isAfterTime);
Yup.addMethod(Yup.string, "isBeforeTime", isBeforeTime);
Yup.addMethod(Yup.string, "dateLessThanEndDate", dateLessThanEndDate);
Yup.addMethod(Yup.array, "periodicMinLength", periodicMinLength);


export const validationSchema = Yup.object({
  publication_date: Yup.string("Select publication date")
    .required("PUBLICATION_DATE_IS_REQUIRED")
    .futureDatesOnly("GREATER_THAN_CURRENT_TIME")
    // .when('editID', (other, schema) => (other ? schema.futureDatesOnly(Yup.ref("editID"), "GREATER_THAN_CURRENT_TIME") : schema))
    ,
  start_date: Yup.string()
    .when('publication_date', (other, schema) => (other ? schema.dateGreaterThanPublication(
      Yup.ref("publication_date"),
      "GREATER_THAN_PUBLICATION_DATE"
    ) : schema))
    .required("START_DATE_IS_REQUIRED"),
  end_date: Yup.string().nullable()
    .when('start_date', (other, schema) => (other ? schema.isAfterTime(Yup.ref("start_date"), "GREATER_THAN_START_TIME") : schema))
    .required("END_DATE_IS_REQUIRED"),
  periodical_maintenance: Yup.array()
    .when('is_periodical_maintenance', (other, schema) => (other ? schema.periodicMinLength(Yup.ref("is_periodical_maintenance"), "SELECT_ONE_PERIODIC_MAINTENANCE") : schema)),
});

function isBeforeTime(ref, msg) {
  return this.test('isBeforeTime', msg, function (value) {
    if (this.resolve(ref) && this.resolve(ref) !== '-' && value && value !== '-') {
      let checkField = moment(this.resolve(ref)).utc();
      let valueField = moment(value).utc();
      if (valueField instanceof moment) {
        return (valueField.isSameOrBefore(checkField));
      }
    }
    return true;
  })
}
function periodicMinLength(ref, msg) {
  return this.test('SELECT_ONE_PERIODIC_MAINTENANCE', msg, function (value) {
    // console.log(value.length <= 1)
    // console.log(this.resolve(ref))
    // console.log(this.resolve(ref) === true)
    if (this.resolve(ref) && value.length <= 1) {
      // console.log('small')
      return false
    }
    return true;
  })

}
function isAfterTime(ref, msg) {
  return this.test('isAfterTime', msg, function (value) {
    if (this.resolve(ref) && this.resolve(ref) !== '-' && value && value !== '-') {
      let checkField = moment(this.resolve(ref)).utc();
      let valueField = moment(value).utc();
      if (valueField instanceof moment) {
        return (valueField.isAfter(checkField));
      }
    }
    return true;
  })
}

function futureDatesOnly(msg) {
  return this.test('futureDatesOnly', msg, function (value) {
    //in case of create alert, no need of validation. On clicking save, take current time
    let currentField;
    if (value instanceof moment) {
      currentField = value.utc();
    } else {
      currentField = moment(value).utc();
    }
    if (currentField instanceof moment) {
      let today = moment().utc();
      return (currentField.isSameOrAfter(today));
    }
    return true;
  });
}
function dateGreaterThanPublication(ref, msg) {
  return this.test('dateGreaterThanPublication', msg, function (value) {
    let currentField;
    if (this.resolve(ref)) {
      if (value instanceof moment) {
        currentField = value.utc();
      } else {
        currentField = moment(value).utc();
      }
      let checkField = moment(this.resolve(ref)).utc();
      if (currentField.diff(checkField, "minutes") >= 0) return true;
      else return false;
    }
    return true;
  });
}
function dateLessThanEndDate(ref, msg) {
  return this.test('dateLessThanEndDate', msg, function (value) {
    let currentField;
    if (this.resolve(ref)) {
      if (value instanceof moment) {
        currentField = value.utc();
      } else {
        currentField = moment(value).utc();
      }
      let checkField = moment(this.resolve(ref)).utc();
      if (currentField.diff(checkField, "minutes") >= 0) return true;
      else return false;
    }
    return true;
  });
}