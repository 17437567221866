import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

class DisplayMessage extends Component {

  render() {
    const Div = styled.div`
            font-size: 11px;
      }
    `;
    return (
      <Div>
        {(this.props.intl.locale === 'de') ? (this.props.rowData.message_de ? this.props.rowData.message_de : '-') : (this.props.rowData.message_en ? this.props.rowData.message_en : '-')}
      </Div>
    )
  }
}

export default injectIntl(DisplayMessage);