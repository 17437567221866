import React, { Component } from "react";
import CommunicationPossibleContext from '../../contexts/communication-possible-context';
import WarningPopup from '../warning-popup/warning-popup'

class Notifier extends Component {
  constructor(props) {
    super(props);
    this.state = {showWarningPopup: true}
  }

  componentDidMount() {
    console.log(this.context)
    this.setState({ isCommunicationPossible: this.context.isCommunicationPossible })
    if (!this.state.isCommunicationPossible) {
      this.setState({ showWarningPopup: true })
    } 
  }
  closeWarningPopup = () => {
    this.setState({showWarningPopup: false});
  }
  setWarning(isCommunicationPossible) {
    if (!isCommunicationPossible) {
      this.setState({ showWarningPopup: true })
    } 
  }
  render() {
    return (
      <div>
      <CommunicationPossibleContext.Consumer>
      {({ isCommunicationPossible, setComm,showWarningPopup,setWarning }) => (
          <div className="create-alert  text-capitalize fs-16 mr-1 notifier">
                <WarningPopup isOpen={showWarningPopup} toggle={setWarning} message="WARNING_FOR_COMMUNICATION_POSSIBLE"/>
          </div>
        )}
      </CommunicationPossibleContext.Consumer>
      </div>
    );
  }
}

Notifier.contextType = CommunicationPossibleContext;

export default Notifier;
