import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute ({component: Component, ...rest}) {
    return (
      <Route
        {...rest}
        render={(props) => localStorage.getItem("jwt_token")
          ? <Component {...props} />
          : <Redirect to={{pathname: '/login', state:'not-logged'}}/>}
      />
    )
  }
  
