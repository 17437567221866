
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Sort from './../assets/icons/Sort.svg';
import SortAsc from './../assets/icons/Sort_asc.svg';
import SortDesc from './../assets/icons/Sort_desc.svg';

export function customSortCaret(order, column) {
  if (!order) return (<span className="d-inline-flex align-items-center px-2"><img src={Sort} alt="" /></span>);
  else if (order === 'asc') return (<span className="d-inline-flex align-items px-2"><img src={SortAsc} alt="" /></span>);
  else if (order === 'desc') return (<span className="d-inline-flex align-items-center px-2"><img src={SortDesc} alt="" /></span>);
  return null;
}

export function customTableHeader(column, colIndex, { sortElement, filterElement }) {
  return (
    <div className="d-inline-flex text-left">
      <span className="text">
        {tableHeaderTranslation(column, colIndex)}
      </span>{sortElement}
    </div>
  );
}
export function tableHeaderTranslation(column, colIndex) {
  return (
    <FormattedMessage id={column.text}
      defaultMessage={column.text} />
  );
}
