import './polyfill';
import React from "react";
import ReactDOM from "react-dom";
import { addLocaleData, IntlProvider } from "react-intl";
import locale_de from "react-intl/locale-data/de";
import locale_en from "react-intl/locale-data/en";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import * as CONSTANTS from "./constants/constants";
import "./index.css";
import Routing from "./routes/routing";
import * as serviceWorker from "./serviceWorker";
import "./styles/main.scss";
import messages_de from "./translations/de.json";
import messages_en from "./translations/en.json";
import Header from './pages/header/header';
import Notifier from './components/notifier/notifier';
import UserInfoContext from './contexts/user-info-context';
import CommunicationPossibleContext from './contexts/communication-possible-context';
import EntitlementsContext from './contexts/entitlements-context';
import {withRouter} from 'react-router-dom';


export const themes = {
  light: {
    foreground: "#000000",
    background: "#eeeeee"
  },
  dark: {
    foreground: "#ffffff",
    background: "#222222"
  }
};

addLocaleData([...locale_en, ...locale_de]);
const messages = {
  de: messages_de,
  en: messages_en
};
// const language = navigator.language.split(/[-_]/)[0];  // language without region code

export const HeaderContext = React.createContext({
  theme: themes.dark,
  toggleTheme: () => { },
  language: CONSTANTS.LANGUAGES.EN,
  setLanguage: () => { },
  isMenuOpen: false,
  toggleMenu: () => { }
});
class ThemeTogglerButton extends React.Component {
  render() {
    return (
      <HeaderContext.Consumer>
        {({ theme, toggleTheme }) => (
          <button
            onClick={toggleTheme}
            style={{ backgroundColor: theme.background }}
          >
            Toggle Theme
          </button>
        )}
      </HeaderContext.Consumer>
    );
  }
}
export default ThemeTogglerButton;

class App extends React.Component {
  constructor(props) {
    super(props);
    
    this.toggleTheme = () => {
      this.setState(state => ({
        theme: state.theme === themes.dark ? themes.light : themes.dark
      }));
    };
    this.toggleMenu = () => {
      this.setState(state => ({
        isMenuOpen: !state.isMenuOpen
      }));
    };
    this.setLanguage = code => {
      this.setState(state => ({
        language: CONSTANTS.LANGUAGES[code]
      }));
    };
   
    this.setUserInfo = (data) => {
     let state = this.state;
     state.userInfo = data;
     this.setState({state})
    }
    this.setCommunication = (data) => {
     let state = this.state;
     state.isCommunicationPossible = data;
     this.setState({state})
    }
    this.setWarning = (val) => {
      this.setState({ showWarningPopup: !this.state.showWarningPopup })
  }
  this.toggleComm = (comm) => {
      this.setState({ isCommunicationPossible: !this.state.isCommunicationPossible })
  }
  this.setEntitlements = (data) => {
    let state = this.state;
     state.entitlements = data;
     this.setState({state})
  }
    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      theme: themes.light,
      toggleTheme: this.toggleTheme,
      language: CONSTANTS.LANGUAGES.EN,
      setLanguage: this.setLanguage,
      isMenuOpen: true,
      toggleMenu: this.toggleMenu,
      isCommunicationPossible: true,
      setComm: this.setComm,
      setWarning: this.setWarning,
      toggleComm: this.toggleComm,
      setUserInfo: this.setUserInfo,
      setCommunication: this.setCommunication,
      setEntitlements: this.setEntitlements,
      entitlements: []
    };
    this.onClickApp = this.onClickApp.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    console.log(window.location.pathname)

    console.log(window.location.pathname === '/home')
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillReceiveProps(nextProps) {
    console.log(this.props.location)
  }
  componentDidUpdate() {
    console.log(window.location.pathname)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
    if (window.innerWidth < 768) {
      this.setState({
        isMenuOpen: false
      });
    } else {
      this.setState({
        isMenuOpen: false
      });
    }
  }

  onClickApp(e) {
    if (this.state.width < 768 && (e.target.classList.contains('nav-item') || (e.target.parentElement && e.target.parentElement.classList.contains('nav-item')))) {
      this.setState({
        isMenuOpen: false
      });
    }
  }
  render() {
    window.addEventListener("unhandledrejection", function (event) {
      console.warn("WARNING: Unhandled promise rejection. Reason: " + event.reason, event);
    });
    return (
      <HeaderContext.Provider value={this.state}>
      <UserInfoContext.Provider value={this.state}>
      <CommunicationPossibleContext.Provider value={this.state}>
      <EntitlementsContext.Provider value={this.state}>
         <IntlProvider
          locale={this.state.language.code}
          messages={messages[this.state.language.code]}
          textComponent={React.Fragment}
        >
          <div className={window.location.pathname === '/login' || window.location.pathname === '/home' ? 'main-container login-page': 'main-container' } onClick={this.onClickApp}>
            <ToastContainer
              className='toast-container' toastClassName="custom-toast"
              position="top-center"
              autoClose={5000} hideProgressBar
              newestOnTop closeOnClick
              rtl={false} pauseOnVisibilityChange
              draggable pauseOnHover
            />
            {window.location.pathname !== '/home'}
            {window.location.pathname === '/login' || window.location.pathname === '/home'? '' : <Header></Header>}
            
            <Routing />
          </div>
        </IntlProvider>
        </EntitlementsContext.Provider>
        </CommunicationPossibleContext.Provider>
        </UserInfoContext.Provider>
      </HeaderContext.Provider>
      
    );
  }
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
withRouter(App);

