import {token_url, user_info_url} from './open-id-config';
const baseURL = process.env.REACT_APP_API_BASE_URL;

export const ApiPath = {
  list: `${baseURL}maintenance/`,
  system: `${baseURL}system/`,
  category: `${baseURL}category/`,
  function_category: `${baseURL}function_category/`,
  function_category_systems: `${baseURL}function_category_systems/`,
  communication_status: `${baseURL}communication-status/`,
  xmlRequest: `${baseURL}xentry-status/`,
  openId: `${token_url}`,
  userInfo: `${user_info_url}`,
  authorization: `${baseURL}authorization/`
};

export default ApiPath;