import axiosInstance from './interceptor';
import { daimler_logout_url, aws_logout_url, authorisation_url } from './../constants/open-id-config'

export function logout() {
  let config = { type: 'logout' }
  localStorage.clear();
  axiosInstance.post(daimler_logout_url, config).then(res => {
    axiosInstance.get(aws_logout_url, config).then(res => {
    })
      .catch(err => {
        window.location = authorisation_url;
        // window.location = daimler_logout_url;
      })
      .catch(err => {
        this.props.history.push("/login");

      })

  })
}