import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import "./header.scss";
import Logo from "./../../assets/images/Daimler Logo.svg";
import * as CONSTANTS from "./../../constants/constants";
import { HeaderContext } from "./../../index";
import CommunicationToggle from "./communication-toggle/communication-toggle";
import SideNav from "./sideNav/sideNav";
import CommunicationPossibleContext from './../../contexts/communication-possible-context';
import UserInfoContext from './../../contexts/user-info-context';
import EntitlementsContext from './../../contexts/entitlements-context';

import axiosInstance from "./../../utlities/interceptor";
import { aws_logout_url, daimler_logout_url, logout_authorisation_url } from '../../constants/open-id-config'
import { logout } from '../../utlities/logout';
import ApiPath from '../../constants/api-path';
import Notifier from '../../components/notifier/notifier';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      logOutOpen: false,
      userInfo: '',
      onLoad: true,
      onLoadComm: true,
    };

  }
  setData = (setData) => {
    this.getUserInfo(setData);
    setData(this.state.userName)
    this.setState({ onLoad: false })
  }
  getUserInfo = (setData) => {
    if (localStorage.getItem('jwt_token')) {
      let config = { type: 'userInfo' }
      axiosInstance.get(`${ApiPath.userInfo}`, config).then(res => {
        this.setState({ userName: res.data.username.replace('Daimler_', '') })
        setData(res.data.username.replace('Daimler_', ''));
        this.setEntitlements(this.context.setEntitlements, res.data.username.replace('Daimler_', ''))
      }).catch(error => {
        console.log(error)
      })
    }
  }
  setComm = (setCommunication) => {
    this.getCommunicationPossible(setCommunication)
    this.setState({ onLoadComm: false })
  }
  getCommunicationPossible = (setCommunication) => {
    if (localStorage.getItem('jwt_token')) {
      axiosInstance.get(`${ApiPath.communication_status}`).then(res => {
        this.setState({ isCommunicationPossible: res.data.status_info.com_status })
        setCommunication(this.state.isCommunicationPossible)
        if (!this.state.isCommunicationPossible) {
          this.setState({ showWarningPopup: true })
        }
        return (
          <Notifier></Notifier>
        )
      });
    }
  }
  setEntitlements = (setEntitlements, userName) => {
    this.getEntitlements(setEntitlements, userName)
    this.setState({ onLoadEntitlement: false })
  }
  getEntitlements = (setEntitlements, userName) => {
    axiosInstance.get(`${ApiPath.authorization}${userName}`).then(res => {
      setEntitlements(res.data.attrs.dcxIapAuthGrps)
      // setEntitlements(["WRMNSERV.StartAuthorization","WRMNSERV.KillSwitchAccess", "WRMNSERV.TestSuiteAccess", "WRMNSERV.DataEntry","WRMNSERV.ComponentsCreation"])
      // setEntitlements(["WRMNSERV.StartAuthorization","WRMNSERV.KillSwitchAccess", "WRMNSERV.TestSuiteAccess", "WRMNSERV.DataEntry"])

    })
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  toggleLogout = () => {
    this.setState({ logOutOpen: !this.state.logOutOpen })
  }
  logout() {
    let config = { type: 'logout' }
    localStorage.clear();
    axiosInstance.post(daimler_logout_url, {}).then(res => {
      axiosInstance.get(aws_logout_url, config).then(res => {
      })
        .catch(err => {
          window.location = logout_authorisation_url;
        })


    })
  }
  render() {
    return (
      <HeaderContext.Consumer>
        {({ language, setLanguage, isMenuOpen, toggleMenu }) => (
          <div
            className={
              isMenuOpen
                ? "header h-60 side-nav-margin"
                : "header h-60 side-nav-close-margin"
            }
          >
            <SideNav isOpen={isMenuOpen} toggleMenu={toggleMenu} />
            <div className="top-bar d-flex">
              <div className="left text-capitalize d-flex flex-column  logo-holder">
                <img src={Logo} alt="Logo" className="logo" />
                <span className="logo-name">
                  <FormattedMessage id="LOGO-NAME"></FormattedMessage>
                </span>
              </div>
              <CommunicationPossibleContext.Consumer>
                {({ isCommunicationPossible, setCommunication, showWarningPopup, setWarning, toggleComm }) => (
                  <div className="center-align">
                    <span>{this.state.onLoadComm && localStorage.getItem('jwt_token') ? this.setComm(setCommunication) : ''}</span>
                    {!isCommunicationPossible ? <div className="warning-message">
                      <FormattedMessage id="WARNING_FOR_COMMUNICATION_POSSIBLE"></FormattedMessage>
                    </div> : ''}
                    <Notifier></Notifier>

                  </div>)}

              </CommunicationPossibleContext.Consumer>
              <div className="right d-flex flex-row">
                <div className="right-first"></div>
                <div className="header-toggle mr-1">

                  <CommunicationToggle></CommunicationToggle>
                </div>
                <div className="language mr-3">
                  <ButtonDropdown
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                  >
                    <DropdownToggle caret>
                      <img
                        src={language.image}
                        alt="language"
                        className="flag"
                      />
                      &nbsp;&nbsp;
                      {language.code}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => setLanguage("EN")}>
                        <img
                          src={CONSTANTS.LANGUAGES.EN.image}
                          alt="EN - Language"
                          className="flag mr-1"
                        />
                        &nbsp;&nbsp;
                        {CONSTANTS.LANGUAGES.EN.code}
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={() => setLanguage("DE")}>
                        <img
                          src={CONSTANTS.LANGUAGES.DE.image}
                          alt="DE - Language"
                          className="flag mr-1"
                        />
                        &nbsp;&nbsp;
                        {CONSTANTS.LANGUAGES.DE.code}
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
                <div className="user-name">
                  <ButtonDropdown
                    isOpen={this.state.logOutOpen}
                    toggle={this.toggleLogout}
                  >
                    <DropdownToggle caret>
                      <div className="greetings d-flex flex-column text-right desktop-only">
                        <span className="text fs-11">
                          <FormattedMessage
                            id="WELCOME"
                            defaultMessage="Welcome"
                            description="Welcome header"
                          />
                        </span>
                      </div>
                      <UserInfoContext.Consumer>
                        {({ userInfo, setUserInfo }) => (
                          <span>
                            <span>{this.state.onLoad && localStorage.getItem('jwt_token') ? this.setData(setUserInfo) : ''}</span>
                            <span className="name fs-15">
                              {userInfo}</span>
                          </span>
                        )}
                      </UserInfoContext.Consumer>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={logout}>
                        Logout
                         &nbsp;&nbsp;
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </div>
            </div>
          </div>
        )}
      </HeaderContext.Consumer>

    );
  }
}
Header.contextType = EntitlementsContext;
