import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Information from "./../../../assets/icons/Information.svg";
import './communication-toggle.scss'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import CommunicationPossibleDisable from './../communication-possible-disable/communication-possible-disable';
import ApiPath from "../../../constants/api-path";
import axiosInstance from "../../../utlities/interceptor";
import CommunicationPossibleContext from '../../../contexts/communication-possible-context';
import EntitilementsContext from '../../../contexts/entitlements-context';
import { entitlements } from '../../../constants/entitlements';
// import Disabled from './../../../assets/icons/KillSwitchIcon_Disabled.png';
// import Enabled from './../../../assets/icons/KillSwitchIcon_Enabled.png';

// Communicationtoggle switch can be toggled only for users with entitlement KillSwitchAccess

class CommunicationToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_communication_possible: true,
      showDisableModal: false,
      popupMessage: '',
      toggleActive: false,
      onLoad: true
    };
    this.toggleCommunication = this.toggleCommunication.bind(this);
  }
  toggleCommunication() {
    if (this.state.isAuthorized) {
    this.setState({ showDisableModal: true })
    if (this.context.isCommunicationPossible) {
      this.setState({ popupMessage: "ARE_YOU_SURE_TO_DISABLE" })
    } else {
      this.setState({ popupMessage: "ARE_YOU_SURE_TO_ENABLE" })
    }
  }
}
  cancel = () => {
    this.setState({ showDisableModal: false })
  }
  disableCommuncation = () => {
    let data = { user_id: "6", status: !this.context.isCommunicationPossible };
    axiosInstance.post(ApiPath.communication_status, data).then(res => {
      this.context.toggleComm(this.context.isCommunicationPossible);
    })
      .catch(error => {
        console.log(error)
      });

    this.setState({ showDisableModal: false })
  }


  onToggle = () => {
    this.setState({ toggleActive: !this.state.toggleActive });
    this.setState({ showDisableModal: true })
    if (this.context.isCommunicationPossible) {
      this.setState({ popupMessage: "ARE_YOU_SURE_TO_DISABLE" })
    } else {
      this.setState({ popupMessage: "ARE_YOU_SURE_TO_ENABLE" })
    }
  }
checkEntitlements = (entitlementsArray) => {
  if (this.state.onLoad && entitlementsArray.length > 0) {
    if (!entitlementsArray.includes(entitlements['killSwitchAccess'])) {
      this.setState({ showWarningPopup: true, onLoad: false, isAuthorized: false })
    }
    else {
      this.setState({isAuthorized: true, onLoad: false})
    }
  }
}
  render() {
    const popover = (
      <Popover id="popover-basic">
        <FormattedMessage id="COMMUNICATION_HELP_TEXT" />
      </Popover>
    );
    return (
      <div className="communication-toggle text-center">
        <CommunicationPossibleContext.Consumer>
          {({ isCommunicationPossible, setComm, showWarningPopup, setWarning, toggleComm }) => (
            <EntitilementsContext.Consumer>
              {({entitlements}) => (
            <div className="create-alert  text-capitalize fs-16  notifier communication-possible">
                              {this.state.onLoad?this.checkEntitlements(entitlements): ''}

              <CommunicationPossibleDisable isOpen={this.state.showDisableModal} disableCommunication={this.disableCommuncation}
                toggle={this.cancel} message={this.state.popupMessage} />
                            <label className="switch ml-2">
                <input
                  type="checkbox"
                  id="is_communication_possible"
                  name="is_communication_possible"
                  className={'communication-check ' + this.state.isAuthorized? 'disable': ''}
                  checked={isCommunicationPossible}
                  onChange={this.toggleCommunication}
                />
                <span className={!this.state.isAuthorized? 'slider round disable': 'slider round'} />
              </label>
              <label className="communication-check fs-12">
                {isCommunicationPossible?<FormattedMessage id="COMMUNICATION_ENABLED"/>:<FormattedMessage id="COMMUNICATION_DISABLED"/>}
                <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
                  <Button id="Popover-communication" type="button" className="popover-btn btn btn-secondary">
                    <img src={Information} alt="question mark" />
                  </Button>
                </OverlayTrigger>
              </label>

            </div>
              )}
              </EntitilementsContext.Consumer>
          )}
        </CommunicationPossibleContext.Consumer>
      </div>
    )
  }
}
CommunicationToggle.contextType = CommunicationPossibleContext;
export default CommunicationToggle;
