const app_base_url = process.env.REACT_APP_BASE_URL;
const encoded_base_url = encodeURIComponent(app_base_url)
// set token url
const BASE_URL = "https://wms-dev-pool.auth.us-east-1.amazoncognito.com/";
const TOKEN_ENDPOINT = "oauth2/token";
export const token_url = BASE_URL + TOKEN_ENDPOINT;

const client_id = '67e7h7svsum6ns6oaikrvjj9gc';

//set redirect uri
export const redirect_uri = app_base_url + 'home';

//set client_id_base64
export const client_id_base64 = 'NjdlN2g3c3ZzdW02bnM2b2Fpa3J2amo5Z2M6MXRxaDFmdGtndmlkcWxvNWdyMWNlb2luMmt0NjdsNDNlamE3M3ZvbDBmZmdjczMyNWFibw==';
export const grant_type = 'authorization_code';

//set authorisation url
export const authorisation_url = 'https://wms-dev-pool.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=' + client_id +'&redirect_uri=' + encoded_base_url + encodeURIComponent('home');

//set logout authorisation url
export const logout_authorisation_url = 'https://wms-dev-pool.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=' + client_id + '&redirect_uri=' + encoded_base_url +  encodeURIComponent('login');

//set aws logout api
export const aws_logout_url = 'https://wms-dev-pool.auth.us-east-1.amazoncognito.com/logout?client_id=' + client_id + '&logout_uri=' + encoded_base_url 

//set daimler logout url
export const daimler_logout_url = 'https://sso-int.i.daimler.com/as/authorization.oauth2?response_type=code&client_id=91f78197-a19b-4aeb-bdc6-84146d13218a&redirect_uri=https%3A%2F%2Fwms-dev-pool.auth.us-east-1.amazoncognito.com%2Foauth2%2Fidpresponse&prompt=login'
//set daimler referrer
export const daimler_referrer = 'https://cdiwl-appstest.i.daimler.com/'

//set userinfo url
export const user_info_url = 'https://wms-dev-pool.auth.us-east-1.amazoncognito.com/oauth2/userInfo'
