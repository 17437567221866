import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router";
import styled from 'styled-components';

import Plus from "./../../assets/icons/Plus.svg";
import ApiPath from "./../../constants/api-path";
import axiosInstance from "./../../utlities/interceptor";
import "./List.scss";
import { columns } from "./table-functions";
import EntitlementsContext from '../../contexts/entitlements-context';
import { entitlements } from '../../constants/entitlements';

const rowClasses = (row, rowIndex) => {
  let classes = "custom-row-class ";
  if (!!!row.is_expired) {
    classes += "upcoming";
  }
  else {
    classes += "expired"
  }
  return classes;
};

export default class List extends Component {
  constructor(props) {
    super(props);
    this.newAlert = this.newAlert.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.checkEndOfList = this.checkEndOfList.bind(this);
    this.state = {
      alertList: [],
      totalCount: 0,
      toNewAlert: false,
      pagination: "-1",
      isLoading: true,
      isEndOfList: false,
      isCommunicationPossible: true,
      showWarningPopup: false,
      onLoad: true
    };
  }

  componentDidMount() {
    this.getAlerts();
  }
  getAlerts = () => {
    axiosInstance.get(`${ApiPath.list}`).then(res => {
      const alertList = res.data.result;
      const lastItem = res.data.last_item;
      this.setState({
        alertList: alertList,
        pagination: lastItem,
        isLoading: false,
        totalCount: res.data.total_count,
        activeCount: res.data.active_count,
        plannedCount: res.data.planned_count
      });
      this.checkEndOfList();
    });
  }
  newAlert() {
    this.setState({
      toNewAlert: true
    });
  }

  loadMore() {
    this.setState({
      isLoading: true
    });
    axiosInstance.get(`${ApiPath.list}?${this.state.pagination}&`).then(res => {
      const newAlerts = res.data.result;
      const lastItem = res.data.last_item;
      this.setState(prevState => ({
        alertList: [...prevState.alertList, ...newAlerts]
      }));
      this.setState({
        pagination: lastItem,
        isLoading: false
      });
      this.checkEndOfList();
    });
  }

  checkEndOfList() {
    if (this.state.pagination == null || (this.state.alertList.length === this.state.totalCount)) {
      this.setState({
        isEndOfList: true
      });
    }
  }
  closeWarningPopup = () => {
    this.setState({ showWarningPopup: false });
  }
  findClasses = (row, rowIndex) => {
    let classes = "custom-row-class ";
    if (!!!row.is_expired) {
      classes += "upcoming";
    }
    else {
      classes += "expired"
    }
    if (this.context.entitlements.length > 0 && !this.context.entitlements.includes(entitlements["newAlert"])) {
      classes += " noDataEntry"
    }
    return classes;    
  }
  render() {
    /**IE fix for table */
    const DIV = styled.div`
      table {
        border-collapse: separate !important;
      }
    `;
    if (this.state.toNewAlert === true) {
      return <Redirect push to="/alert" />;
    }

    return (
      <div className="list-container">               
        <div className="mb-4 d-flex mt-4 w-100 pl-3">
          <div className="left">
            <span className="heading text-capitalize">
              <FormattedMessage id="ALERTS" defaultMessage="alerts" />
            </span>
            <div className="count">
              <span>
                <FormattedMessage id="ACTIVE" defaultMessage="Active" />: { this.state.activeCount }
                <span className="planned"><FormattedMessage id="PLANNED" defaultMessage="Planned" />: { this.state.plannedCount }</span>
              </span>
            </div>
          </div>
          <div className="right-align">
            <button className="btn btn-primary fs-13 btn-generic" onClick={this.newAlert}>
              <img src={Plus} alt="" className="pr-2" />
              <FormattedMessage id="NEW_ALERTS" defaultMessage="New Alert" />
            </button>
          </div>
        </div>
        <hr />
        <DIV className={"w-100 pl-3 last-row-sticky"}>
          <BootstrapTable
            keyField="id"
            data={this.state.alertList}
            columns={columns}
            bordered={false}
            bootstrap4={true}
            rowClasses={this.findClasses}
          />
          <div className="d-flex justify-content-center flex-column">
            <div className="text-center">
              {this.state.alertList.length}&nbsp;
              <FormattedMessage id="OF" />
              &nbsp;
              {this.state.totalCount > this.state.alertList.length &&
                this.state.alertList.length !== 0
                ? this.state.totalCount
                : this.state.alertList.length}
            </div>
            {!this.state.isEndOfList && (
              <div className="text-center">
                <span
                  className="load-more text-capitalize pointer btn text-center"
                  onClick={this.loadMore}
                  disabled={this.state.isEndOfList}
                >
                  <FormattedMessage
                    id={
                      this.state.isLoading
                        ? "LOADING"
                        : this.state.isEndOfList && "LOAD_MORE"
                          ? "END_OF_LIST"
                          : "LOAD_MORE"
                    }
                  />
                  {this.state.isLoading && <div className="lds-dual-ring" />}
                </span>
              </div>
            )}
          </div>
        </DIV>
      </div>
    );
  }

}
List.contextType = EntitlementsContext;

