import * as moment from "moment";
import * as queryString from "query-string";
import React, { Component } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { FormattedMessage } from "react-intl";
import { Redirect, withRouter } from "react-router";
import Select from "react-select";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import ApiPath from "../../../constants/api-path";
import axiosInstance from "../../../utlities/interceptor";
import { ALERT_TYPE, DATE_FORMAT, TIME_FORMAT, WEEKDAYS } from "./../../../constants/constants";
import "./form.scss";
import { dayExistCheck, duplicateEntryCheckOnArray } from './../periodic-validation'
import { colourStyles } from './colourStyles';
import deleteIcon from '../../../assets/icons/Delete.svg'
import WarningPopup from './../../../components/warning-popup/warning-popup';

const yesterday = Datetime.moment().subtract();
class createAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toList: false,
      systemList: [],
      defaultSystemValues: null,
      isFirstPeriodic: true,
      isOpen: true
    };
    this.getSystems();
  }
  componentDidMount() {
    let parsed = queryString.parse(this.props.location.search);
    if ("duplicate" in parsed && this.props.values.is_periodical_maintenance) {
      this.props.setFieldValue('publication_date', moment().add(1, 'm'));
      this.setInitialPeriodicArray();
    }
    if ("edit" in parsed && this.props.values.is_periodical_maintenance) {
      this.setInitialPeriodicArray();
    }
  }
  componentWillMount() {
    let parsed = queryString.parse(this.props.location.search);
    if ("edit" in parsed) {
      this.props.setFieldValue('editID', parsed.edit)

    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  setInitialPeriodicArray = () => {
    // In case of edit and duplicate the array should have an extry entry additional to the api data
    let arr = this.props.values.periodical_maintenance;
    arr.push({ day: { value: '' }});
    this.props.setFieldValue('periodical_maintenance', arr)
    this.setState({ periodicalErrors: this.props.values.periodicalErrors.push("") })
  }
  blockDates = (current, dateBefore, dateAfter) => {
    return current.isSameOrAfter(yesterday, 'day');
  }

  getSystems = () => {
    axiosInstance.get(ApiPath.system).then(res => {
      let systemList = res.data;
      let transformed = systemList.map(({ id, name }) => ({
        label: name,
        value: name
      }));

      this.setWeekdaySelect();
      this.setState({
        systemList: transformed,
        defaultSystemValues: transformed.filter(obj =>
          (this.props.values.system ? this.props.values.system : "").includes(
            obj.value
          )
        )
      });
      /* dirty fix need to be moved to proper sequence */
      let parsed = queryString.parse(this.props.location.search);
      if ("duplicate" in parsed) {
        this.props.setFieldValue('publication_date', moment().add(1, 'm'));
      }
      return transformed;
    });
  }

  setWeekdaySelect = () => {
    this.setState({
      weekdayList: WEEKDAYS,
      defaultWeekValues: WEEKDAYS.filter(obj =>
        (this.props.values.periodical_maintenance
          ? this.props.values.periodical_maintenance
          : ""
        ).toString()
          .toLowerCase()
          .includes(obj.value)
      )
    });
  }

  setDate = (momentObj, field) => {
    this.props.setFieldTouched(field, true);
    this.props.setFieldValue(field, momentObj);
    this.props.values[field] = momentObj;
    //update the periodic dates with new start date or end date
    if (this.props.values.is_periodical_maintenance) {
      this.props.values.periodical_maintenance.forEach((element, index) => {
        if (element.day.value) {
          this.findPeriodicStartDate(element.day, index)
          this.findPeriodicEndDate(element.day, index)
        }
      });
    }
  }

  setSelectSystem = (data, field) => {
    let arrayValue = [];
    data.forEach(element => {
      arrayValue.push(element.value);
    });
    this.props.setFieldValue(field, arrayValue);
  }
  setSelectValue = (data, field, index) => {
    if (data) {
      if ((!this.props.values.periodical_maintenance[this.props.values.periodical_maintenance.length - 1].day.value) && 
      (!this.props.values.periodical_maintenance[index].day.value)) {
        data.firstEntry = true;
      }
      else {
        data.firstEntry = false;
      }
      this.props.values.periodical_maintenance[index] = {}
      this.props.values.periodical_maintenance[index].day = data;
      if (data.length < 1) {
        this.props.setFieldValue("periodical_start_date", "");
        this.props.setFieldValue("periodical_end_date", "");
        this.setState({
          isFirstPeriodic: true
        });
      } else if (this.state.isFirstPeriodic) {
        this.props.setFieldValue("periodical_start_date", this.props.values.start_date);
        this.props.setFieldValue("periodical_end_date", this.props.values.end_date);
        this.setState({
          isFirstPeriodic: false
        });
      }
      this.findPeriodicStartDate(data, index);
      this.findPeriodicEndDate(data, index, data.firstEntry);
    }
    // remove the 3 fields if day field is removed
    else {
      this.props.onClear(this.props.values.periodical_maintenance, index)
      this.props.setFieldValue('periodicalErrors', this.props.values.periodicalErrors.splice(index, 1))
    }
  }
  findPeriodicStartDate = (data, index, firstEntry) => {
    this.props.values.start_date = moment(this.props.values.start_date);
    let origStart = this.props.values.start_date.clone();
    let startDiff = (data.dayIndex - this.props.values.start_date.day()) % 7;
    if (startDiff < 0) {
      startDiff += 7;
    }
    let startDate = (this.props.values.start_date.add(startDiff, 'days'));
    //periodical start should be less then endDate date
    let checkField = moment(this.props.values.end_date)
    if (checkField.diff(startDate, "days") >= 0) {
      startDate.set({ h: 0, m: 0 });
      this.props.values.periodical_maintenance[index].periodical_start_date = startDate.clone();
    } else {
      this.props.values.periodical_maintenance[index].periodical_start_date = null;
    }
    this.props.values.start_date = origStart.clone();
  }
  findPeriodicEndDate = (data, index, firstEntry) => {
    this.props.values.end_date = moment(this.props.values.end_date)
    let origEnd = this.props.values.end_date.clone();
    let endDiff = (this.props.values.end_date.day() - data.dayIndex) % 7;
    if (endDiff < 0) {
      endDiff += 7;
    }
    let endDate = (this.props.values.end_date.subtract(endDiff, 'days'));
    let checkField = moment(this.props.values.start_date)
    if (endDate.diff(checkField, "days") >= 0) {
      endDate.set({ h: 0, m: 0 });
      let maintenance = this.props.values.periodical_maintenance;
      maintenance[index].periodical_end_date = endDate.clone();
      this.props.setFieldValue('periodical_maintenance', maintenance)
    } else {
      this.props.values.periodical_maintenance[index].periodical_end_date = null;

    }
    this.props.values.end_date = origEnd.clone();
    //push an empty field set since one day is selected
    let errors = dayExistCheck(this.props.values.periodical_maintenance[index], index, this.props.values.periodicalErrors);
    this.props.setFieldValue('periodicalErrors', errors);
    let periodical_maintenance = this.props.values.periodical_maintenance;
    if (firstEntry) {
      periodical_maintenance.push({ day: { value: '' }})
      this.props.setFieldValue('periodical_maintenance', periodical_maintenance)
    }
    //check whether new entry is duplciate
    if (this.props.values.periodical_maintenance[index].periodical_start_date) {
      this.props.setFieldValue('periodicalErrors', duplicateEntryCheckOnArray(this.props.values.periodical_maintenance, this.props.values.periodicalErrors, index));
    }
  }


  displayError = (field) => {
    if (this.props.touched[field] && this.props.errors[field]) {
      return (
        <div className="errors-text fs-12 text-transform-initial">
          <FormattedMessage id={this.props.errors[field]} />
          {this.state.saveClicked ?<WarningPopup isOpen={this.state.isOpen} toggle={this.close} message="ERROR_WARNING" class="error"/>: ''}
        </div>
      );
    }
  }

  display = (index) => {
    if (this.props.values.periodicalErrors[index])
      return (
        <div className="errors-text fs-12 text-transform-initial">
          {<FormattedMessage id={this.props.values.periodicalErrors[index]} />}
          {this.state.saveClicked ? <WarningPopup isOpen={this.state.isOpen} toggle={this.close} message="ERROR_WARNING" class="error"/> : ''}
        </div>
      );
  }
  cancel = () => {
    this.setState({
      toList: true
    });
  }

  translate(event) {
    event.stopPropagation();
    event.preventDefault();
  }


  onRouteChanged = () => {
    for (const key of Object.keys(this.props.values)) {
      this.props.setFieldValue(key, "");
      if (key === 'type')
        this.props.setFieldValue(key, "m");
    }
    this.setState({
      defaultSystemValues: "",
      defaultWeekValues: ""
    });
    this.props.setFieldValue('publication_date', moment().add(1, 'm'));
    this.getSystems()
  }
  showDates = (index) => {
    if (!this.props.values.periodical_maintenance[index].periodical_start_date) {
      return ''
    }
    return (this.formatDates(this.props.values.periodical_maintenance[index].periodical_start_date) + ' to ' +
      this.formatDates(this.props.values.periodical_maintenance[index].periodical_end_date))
  }
  formatDates = (str) => {
    if (str) {
      var date = new Date(str),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [day, month, date.getFullYear()].join(".");
    }
  }

  deletePeriodicEntry = (index) => {
    let arr = this.props.values.periodical_maintenance;
    let err = this.props.values.periodicalErrors;
    err.splice(index, 1);
    arr.splice(index, 1);
    this.props.setFieldValue('periodical_maintenance', arr)
    //on deletion, check duplication on the whole array
    this.props.setFieldValue('periodicalErrors', duplicateEntryCheckOnArray(this.props.values.periodical_maintenance.slice(), this.props.values.periodicalErrors.slice()))
  }

  assignType = (type) => {
    this.props.setFieldValue('type', type)
    this.props.setFieldValue('is_periodical_maintenance', false);
    this.emptyPeriodic();
  }
  switchPeriodic = () => {
    this.props.setFieldValue('is_periodical_maintenance', !this.props.values.is_periodical_maintenance)
    this.emptyPeriodic()
  }
  emptyPeriodic = () => {
    let periodical_maintenance = this.props.values.periodical_maintenance;
    periodical_maintenance = [{ day: { value: '' }}];
    this.props.setFieldValue('periodical_maintenance', periodical_maintenance)
  }
  setPeriodicDates = (momentObj, field, index, arr) => {
    let periodical_maintenance = this.props.values.periodical_maintenance;
    arr[index][field] = momentObj;
    this.props.setFieldValue('periodical_maintenance', periodical_maintenance);
    this.props.setFieldValue('periodicalErrors', duplicateEntryCheckOnArray(this.props.values.periodical_maintenance, this.props.values.periodicalErrors, index));
  }
  close = () => {
    this.setState({ isOpen: false })
  }
  saveClicked = () => {
    this.setState({ isOpen: true, saveClicked: true })
  }
  render() {
    if (this.state.toList === true) {
      return <Redirect push to="/list" />;
    }
    return (
      <form onSubmit={this.props.handleSubmit} className="alert-form d-flex flex-wrap pt-2">
        <div className="form-group col-xs-10 col-sm-12 col-md-12 col-lg-12">
          <span className="fs-12 mr-5">
            <FormattedMessage id="TYPE" />
            <sup>*</sup>
            <Button id="Popover-type" type="button" className="popover-btn">
              <img
                src={require("../../../assets/icons/Information_2.svg")}
                alt="question mark"
              />
            </Button>
            <UncontrolledPopover
              placement="right"
              target="Popover-type"
              trigger="hover"
            >
              <PopoverBody className="popover-body">
                <FormattedMessage id="TYPE_HELP_TEXT" />
              </PopoverBody>
            </UncontrolledPopover>
          </span>
          <span className="mr-4">
            <input
              type="radio"
              id="information"
              name="type"
              className="default"
              value={ALERT_TYPE.information.value}
              checked={this.props.values.type === 'i'}
              onChange={() => this.assignType('i')}
              onBlur={this.props.handleBlur}
            />
            <label
              htmlFor="information"
              className="type-radio text-capitalize fs-12"
            >
              <FormattedMessage id="INFORMATION" />
            </label>
          </span>
          <span className="mr-4">
            <input
              type="radio"
              id="maintenance"
              name="type"
              className="default"
              value={ALERT_TYPE.maintenance.value}
              checked={this.props.values.type === 'm'}
              onChange={() => this.assignType('m')}
              onBlur={this.props.handleBlur}
            />
            <label
              htmlFor="maintenance"
              className="type-radio text-capitalize fs-12"
            >
              <FormattedMessage id="MAINTENANCE" />
            </label>
          </span>
        </div>
        <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 periodic-enable">
          <div className="fs-12  d-block">

            <input type="checkbox" id="periodic" onChange={this.switchPeriodic}
              checked={this.props.values.is_periodical_maintenance} value={this.props.values.is_periodical_maintenance} />
            <label htmlFor="periodic"></label>
            <label htmlFor="periodic" className="ml-5"><FormattedMessage id="SELECT_IF_PERIODIC" /></label>
          </div>
        </div>
        <div className="form-group col-xs-10 col-sm-6 col-md-6 col-lg-6">
          <span className="fs-12 label-mb d-block">
            <FormattedMessage id="SYSTEM" />
          </span>
          {this.state.defaultSystemValues && (
            <Select
              defaultValue={this.state.defaultSystemValues}
              options={this.state.systemList}
              isMulti
              onChange={data => this.setSelectSystem(data, "system")}
              closeMenuOnSelect={false}
              className="custom-multi-select"
              styles={colourStyles}
            />
          )}
          {!this.state.defaultSystemValues && <div className="lds-dual-ring" />}
        </div>

        <div className="form-group col-xs-10 col-sm-6 col-md-6 col-lg-6">
          <span className="fs-12 label-mb d-block">
            <FormattedMessage id="PUBLICATION_DATE" />
            <sup>*</sup>
          </span>
          <Datetime
            isValidDate={current => this.blockDates(current, "", "")}
            value={
              this.props.values.publication_date &&
                this.props.values.publication_date !== "-"
                ? new Date(moment(this.props.values.publication_date).format())
                : ""
            }
            onChange={momentObj => this.setDate(momentObj, "publication_date")}
            dateFormat={DATE_FORMAT}
            timeFormat={TIME_FORMAT}
            className="custom-datepicker"
          />
          {this.displayError("publication_date")}
        </div>
        <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-3 start-periodical">
          <span className="fs-12 label-mb d-block text-transform-initial">
            <FormattedMessage id="START_OF_EVENT" />
            <sup>*</sup>
          </span>
          <Datetime
            isValidDate={current =>
              this.blockDates(current, this.props.values.end_date, "")
            }
            value={
              this.props.values.start_date &&
                this.props.values.start_date !== "-"
                ? new Date(moment(this.props.values.start_date).format())
                : ""
            }
            onChange={momentObj => this.setDate(momentObj, "start_date")}
            closeOnSelect={false}
            dateFormat={DATE_FORMAT}
            timeFormat={TIME_FORMAT}
            className="custom-datepicker"
            inputProps={{ readOnly: true }}
          />
          {this.displayError("start_date")}
        </div>
        <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-3 endDate-periodical">
          <span className="fs-12 label-mb d-block text-transform-initial">
            <FormattedMessage id="END_OF_EVENT" />
            <sup>*</sup>
            <Button id="Popover-endDate-maintenance" type="button" className="popover-btn">
              <img
                src={require("../../../assets/icons/Information_2.svg")}
                alt="question mark"
              />
            </Button>
            <UncontrolledPopover
              placement="right"
              target="Popover-endDate-maintenance"
              trigger="hover"
            >
              <PopoverBody className="popover-body">
                <FormattedMessage id="END_MAINTENANCE_HELP_TEXT" />
              </PopoverBody>
            </UncontrolledPopover>
          </span>
          <Datetime
            isValidDate={current =>
              this.blockDates(current, "", this.props.values.start_date)
            }
            value={
              this.props.values.end_date && this.props.values.end_date !== "-"
                ? new Date(moment(this.props.values.end_date).format())
                : ""
            }
            onChange={momentObj => this.setDate(momentObj, "end_date")}
            closeOnSelect={false}
            dateFormat={DATE_FORMAT}
            timeFormat={TIME_FORMAT}
            className="custom-datepicker"
            inputProps={{ readOnly: true }}
          />
          {this.displayError("end_date")}
        </div>
        <div className="form-group col-xs-10 col-sm-6 col-md-6 col-lg-6 reason">
          <span className="fs-12 label-mb d-block">
            <FormattedMessage id="REASON" />
          </span>
          <input
            id="reason"
            name="reason"
            label="reason"
            type="text"
            autoComplete="off"
            className={`form-control ${this.props.touched.reason &&
              Boolean(this.props.errors.reason) &&
              "is-invalid"}`}
            value={this.props.values.reason ? this.props.values.reason : ""}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
          />
          {this.displayError("reason")}
        </div>
        <div className="form-group col-xs-10 col-sm-6 col-md-6 col-lg-6 language-switcher-left">
          <p className="fs-12 label-mb d-block">
            <FormattedMessage id="MESSAGE" />
            &nbsp;|&nbsp;EN
          </p>
          <textarea
            id="message_en"
            name="message_en"
            label="message_en"
            value={
              this.props.values.message_en ? this.props.values.message_en : ""
            }
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            rows="4"
            placeholder="Message in English"
            className="w-100 fs-12"
          />
          {this.displayError("message_en")}
          <button onClick={this.translate}>Translate</button>
        </div>
        <div className="form-group col-xs-10 col-sm-6 col-md-6 col-lg-6 language-switcher-right">
          <p className="fs-12 label-mb d-block align-left">
            <FormattedMessage id="MESSAGE" />
            &nbsp;|&nbsp;DE
          </p>
          <textarea
            id="message_de"
            name="message_de"
            label="message_de"
            value={
              this.props.values.message_de ? this.props.values.message_de : ""
            }
            onChange={this.props.handleChange}
            rows="4"
            placeholder="Message in German"
            className="w-100 fs-12"
          />
          {this.displayError("message_de")}
        </div>
        {this.props.values.is_periodical_maintenance ?
          <div className="d-flex flex-wrap pt-2 periodic">
            {this.props.values.periodical_maintenance.map((period, index) => (
              <div className="d-flex flex-wrap pt-2 periodic" key={index}>
                <div className="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3 col-wd periodical-maintenance"
                  title={(!this.props.values.start_date && !this.props.values.end_date) ? "Select start of event and end of event" :
                    (!this.props.values.end_date ? "Select end of event" :
                      (!this.props.values.start_date) ? "Select start of event" : '')}            >
                  <span className="fs-12 label-mb d-block">
                    <FormattedMessage id="PERIODICAL_EVENT" />
                  </span>
                  {this.state.defaultWeekValues && (
                    <Select
                      isDisabled={!this.props.values.start_date || !this.props.values.end_date}
                      defaultValue={this.state.defaultWeekValues}
                      options={this.state.weekdayList}
                      onChange={data =>
                        this.setSelectValue(data, "periodical_maintenance", index)
                      }
                      value={this.props.values.periodical_maintenance[index].day}
                      closeMenuOnSelect={true}
                      className="custom-multi-select"
                      styles={colourStyles}

                    />)
                  }
                  {this.displayError("periodical_maintenance")}
                  {this.display(index)}
                </div>
                <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-3 col-wd start-periodical-maintenance"
                  title={!this.props.values.periodical_maintenance[index].day ? "Select Periodical event" : ''}>
                  <span className="fs-12 label-mb d-block text-transform-initial">
                    <FormattedMessage id="SELECTED_DATES" />
                  </span>
                  <input readOnly={true} className="dates-periodic form-control" type="text"
                    title={!this.props.values.periodical_maintenance[index].day.value ? "Select Periodical event" : ''}
                    disabled={!(this.props.values.periodical_maintenance[index].day.value)}

                    value={this.props.values.periodical_maintenance[index].day.value ?
                      this.showDates(index) : ''}
                  />
                </div>
                <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-3 col-wd start-periodical-maintenance periodic-time"
                  title={!this.props.values.periodical_maintenance[index].day.value ? "Select Periodical event" :
                    this.props.values.periodicalErrors[index] === 'SELECTED_DAY_DOES_NOT_EXIST' ? "Select a valid day" : ''}>
                  <span className="fs-12 label-mb d-block text-transform-initial">
                    <FormattedMessage id="START_TIME" />
                  </span>
                  <Datetime
                    inputProps={{
                      disabled: !(this.props.values.periodical_maintenance[index].day.value) ||
                        this.props.values.periodicalErrors[index] === 'SELECTED_DAY_DOES_NOT_EXIST', readOnly: true
                    }}
                    closeOnSelect={false}
                    isValidDate={current =>
                      this.blockDates(
                        current,
                        this.props.values.periodical_maintenance[index].periodical_end_date,
                        ""
                      )
                      && this.blockDates(current,
                        this.props.values.end_date, this.props.values.start_date)
                    }
                    value={
                      this.props.values.periodical_maintenance[index].periodical_start_date &&
                        this.props.values.periodical_maintenance[index].periodical_start_date !== "-"
                        ? new Date(
                          moment(this.props.values.periodical_maintenance[index].periodical_start_date).format()
                        )
                        : ""
                    }
                    onChange={momentObj =>
                      this.setPeriodicDates(momentObj, "periodical_start_date", index, this.props.values.periodical_maintenance)
                    }
                    dateFormat={false}
                    timeFormat={TIME_FORMAT}
                    className="custom-datepicker"

                  />

                </div>
                <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-3 col-wd endDate-periodical-maintenance periodic-time"
                  title={!this.props.values.periodical_maintenance[index].day.value ? "Select Periodical Maintenance" :
                    this.props.values.periodicalErrors[index] === 'SELECTED_DAY_DOES_NOT_EXIST' ? "Select a valid day" : ''}>
                  <span className="fs-12 label-mb d-block text-transform-initial">
                    <FormattedMessage id="END_TIME" />
                  </span>
                  <Datetime
                    inputProps={{
                      disabled: !(this.props.values.periodical_maintenance[index].day.value) ||
                        this.props.values.periodicalErrors[index] === 'SELECTED_DAY_DOES_NOT_EXIST',
                      readOnly: true
                    }}
                    isValidDate={current =>
                      this.blockDates(
                        current,
                        "",
                        this.props.values.periodical_maintenance[index].periodical_start_date
                      )
                      && this.blockDates(current,
                        this.props.values.end_date, this.props.values.start_date)
                    }
                    value={
                      this.props.values.periodical_maintenance[index].periodical_end_date &&
                        this.props.values.periodical_maintenance[index].periodical_start_date !== "-"
                        ? new Date(
                          moment(this.props.values.periodical_maintenance[index].periodical_end_date).format()
                        )
                        : ""
                    }
                    onChange={momentObj =>
                      this.setPeriodicDates(momentObj, "periodical_end_date", index, this.props.values.periodical_maintenance)
                    }
                    closeOnSelect={false}
                    dateFormat={false}
                    timeFormat={TIME_FORMAT}
                    className="custom-datepicker"

                  />
                </div>
                {this.props.values.periodical_maintenance[index].day.value ? <div className="delete-periodic" onClick={() => this.deletePeriodicEntry(index)}>
                  <img src={deleteIcon} alt="" className="pointer delete" />
                </div> : null}
              </div>

            ))}
          </div>
          : null}
        <div className="form-group col-xs-10 col-sm-12 col-md-12 col-lg-12">
          <button
            onClick={this.cancel}
            className="btn btn-secondary text-capitalize fs-13 actions"
          >
            <FormattedMessage id="CANCEL" />
          </button>
          <button
            type="submit"
            variant="raised"
            title={(this.props.submitCount) ? "Correct the above errors" : ""}
            className="btn btn-primary text-capitalize fs-13 actions"
            onClick={this.saveClicked}
          >
            <FormattedMessage id={this.props.isDataSaved ? "SAVING" : "SAVE"} />
          </button>
        </div>
      </form>
    );
  }
}

export default withRouter(createAlert);
