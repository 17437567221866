import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import NAVIGATION_ITEMS from "./nav-items";
import "./sideNav.scss";
import CommunicationToggle from "../communication-toggle/communication-toggle";
import EntitlementsContext from '../../../contexts/entitlements-context';
import { entitlements } from '../../../constants/entitlements';

export default class SideNav extends Component {  
  generateNavItems = (type) => {
    let navItems = [];
    NAVIGATION_ITEMS[type].forEach(item => {     
      navItems.push(
        <NavLink
          to={item.url}
          exact
          activeClassName="active"
          key={item.id}
          className={item.type && !this.context.entitlements.includes(entitlements[item.type]) ? 'nav-item fs-14 text-capitalize grey-bg' :  
                    'nav-item fs-14 text-capitalize'}
          title={item.text}
          onClick = {(e) => this.handleClick(e, item)}
        >
          <img
            src={require(`./../../../assets/icons/${item.image}`)}
            alt={item.text}
            className={item.class?item.class:'pr-16'}
          />
          <span className="text">
            <FormattedMessage
              id={item.translation}
              defaultMessage={item.text}
            />
          </span>
        </NavLink>
      );
    });
    return navItems;
  }
  handleClick = (e, item) => {
    /* testsuite page is enabled only for the users with entitlement TestSuiteAccess
      createAlert page is enabled only for the users with entitlement DataEntry*/
    if(item.type === 'apiValidator' && !this.context.entitlements.includes(entitlements['apiValidator'])) 
      e.preventDefault()
    if(item.type === 'newAlert' && !this.context.entitlements.includes(entitlements['newAlert'])) 
      e.preventDefault()
}
  render(props) {
    return (
      <div className="sideNav-container d-flex flex-column">
        <div className={`hamburger ${this.props.isOpen ? "open" : "close-nav"}`} onClick={this.props.toggleMenu}>
          <div className={`greetings  d-inline-flex flex-column text-left mobile-only ${this.props.isOpen ? "open" : "close-nav"}`}>
            <span className="text fs-11 font-blue-green">
              <FormattedMessage
                id="WELCOME"
                defaultMessage="Welcome"
                description="Welcome header"
              />
            </span>
            <span className="name fs-15">John Doe</span>
          </div>
          <div
            id="nav-icon1"
            className={this.props.isOpen ? "open" : "close-nav"}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <ul
          className={
            this.props.isOpen
              ? "sideNav navbar-nav side-nav-width open"
              : "sideNav navbar-nav side-nav-close-width closed"
          }
        >
          <hr className="mobile-only mt-1" />
          <div className="toggle"><CommunicationToggle></CommunicationToggle></div>
          {this.generateNavItems("user")}
          <hr />
          {this.generateNavItems("admin")}
        </ul>
      </div>
    );
  }
}
SideNav.contextType = EntitlementsContext;