import React, { Component } from 'react';

import './checkbox.scss';

export default class checkbox extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    this.props.toggleSystems(e.target.checked, this.props.rowData, this.props.col)
  }
  render() {
    return (
      <div className={this.props.enableDataEntry ? 'checkbox': 'checkbox disable'}>
        <input type='checkbox'
          ref={this.input}
          onChange={this.handleChange}
          name={`${this.props.rowData.id}-${this.props.col}`}
          id={`${this.props.rowData.id}-${this.props.col}`}
          defaultChecked={this.props.rowData[this.props.col] ? true : false} 
          disabled={!this.props.enableDataEntry}/>
        <label
          className={`pointer ${(this.props.isSaving || !this.props.enableDataEntry) && 'pointer-events-none'}`}
          disabled={this.props.isSaving || !this.props.enableDataEntry}
          htmlFor={`${this.props.rowData.id}-${this.props.col}`}>
        </label>
      </div>
    )
  }
}
