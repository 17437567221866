import { Formik } from "formik";
import * as moment from "moment";
import * as queryString from "query-string";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router";
import AlertDataContext from "../../contexts/alert-data-context";
import axiosInstance from "../../utlities/interceptor";
import ApiPath from "./../../constants/api-path";
import { ALERT_TYPE, DATE_TIME_FORMAT } from "./../../constants/constants";
import "./createAlert.scss";
import Form from "./form/form";
import { validationSchema } from './validationSchema';
import WarningPopup from '../../components/warning-popup/warning-popup';
import { entitlements } from '../../constants/entitlements';
import EntitlementsContext from "../../contexts/entitlements-context";

// this page is visible only to users with entitlement ComponentsCreation

class createAlert extends Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.state = {
      toList: false,
      isSubmitting: false,
      editID: -1,
      alertData: {},
      periodicValidation: 'valid',
      showWarningPopup: false,
      onLoad: true,
      isAuthorized: false,
      values: {
        type: ALERT_TYPE.maintenance.value,
        system: "",
        publication_date: moment().add(1, 'm'),
        start_date: "",
        end_date: "",
        reason: "",
        message_en: "",
        message_de: "",
        is_communication_possible: false,
        periodical_maintenance: [{ day: { value: '' } }],
        periodicValidation: 'valid',
        is_periodical_maintenance: false,
        editID: -1,
        periodicalErrors: [],
      }
    };
  }
  componentWillMount() {
    let alertData = this.context.alertData;
    let setContextAlertData = this.context.setData;
    let parsed = queryString.parse(this.props.location.search);
    if ("edit" in parsed) {
      let state = this.state.values;
      state.editID = parsed.edit;
      this.setState({
        values: state
      });
      this.setState({ editID: parsed.edit })
    }
    if (
      this.props.location.search.length !== 0 &&
      !alertData.hasOwnProperty("id")
    ) {
      this.cancel();
    } else if (
      this.props.location.search.length > 0 &&
      alertData.hasOwnProperty("id")
    ) {
      this.fillData(alertData);
    } else {
      setContextAlertData();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.onRouteChanged();
    }
  }
  checkEntitlements = (entitlementsArray) => {
    if (this.state.onLoad && entitlementsArray.length > 0) {
      if (!entitlementsArray.includes(entitlements['newAlert'])) {
        this.setState({ showWarningPopup: true, onLoad: false, isAuthorized: false })
      }
      else {
        this.setState({ isAuthorized: true, onLoad: false })
      }
    }
  }
  cancel = () => {
    this.setState({
      toList: true
    });
  }

  fillData = (data) => {
    this.setState({
      values: { ...data },
      is_communication_possible: data.is_communication_possible
    });
  }

  submitValues = (data) => {
    console.log(data)
    this.setState({ periodicValidation: 'valid' })
    //in case of create alert, update publication date with current date and time
    if (this.state.editID === -1) {
      data.publication_date = moment().add(1, 'm');
    }
    //donot save if there are errors
    if (!data.is_periodical_maintenance) {
      delete data.periodical_maintenance;
      this.postValues(data);
      return;
    }
    else {
      for (var element of data.periodicalErrors) {
        if (element !== "") {
          this.setState({ periodicValidation: 'invalid' })
          break;
        }

      };
      if (this.state.periodicValidation.localeCompare('invalid') !== 0) {
        data.periodical_maintenance.pop();
        this.postValues(data)
      }
    }
  }
  postValues = (data) => {
    this.setState({
      isSubmitting: true,
    });
    delete data.periodical_start_date;
    delete data.periodical_end_date;
    let formData = this.convertMomentToString(data);
    let apiPath =
      this.state.editID !== -1
        ? `${ApiPath.list}${this.state.editID}/`
        : ApiPath.list;
    let type = "post";
    if (this.state.editID === -1) {
      Object.assign(formData, { user_id: "6" });
    } else {
      delete formData["id"];
      delete formData["user_id"];
      type = "put";
    }
    this.postData(formData, apiPath, type);
  }
  convertMomentToString = (data) => {
    let value = {};
    value = data;
    Object.keys(value).forEach(element => {
      if (moment.isMoment(value[element])) {
        value[element] = moment(value[element], DATE_TIME_FORMAT)
          .utc()
          .format()
          .toString();
      }
    });
    return JSON.parse(JSON.stringify(value));
  }

  postData = (data, apiPath, type) => {
    Object.keys(data).forEach(
      key =>
        (data[key] == null || data[key] === "" || data[key] === "-") &&
        delete data[key]
    );
    axiosInstance[type](apiPath, data)
      .then(res => {
        this.setState({
          isSubmitting: false,
          toList: true
        });
      })
      .catch(error => {
        this.setState({
          isSubmitting: false
        });
      });
  }
  onRouteChanged = () => {
    this.setState({
      editID: -1
    });
  }
  setDates = (momentObj, field) => {
    let items = this.state.values;
    items[field] = momentObj;
    this.setState({ values: items })
  }
  closeWarningPopup = () => {
    this.setState({ showWarningPopup: false });
    this.props.history.push("/list")
  }
  setWarning() {
    this.setState({ showWarningPopup: true })
  }
  render() {
    if (this.state.toList === true) {
      return <Redirect push to="/list" />;
    }
    return (
      <EntitlementsContext.Consumer>
        {({ entitlements }) => (
          <div>
            {this.state.onLoad ? this.checkEntitlements(entitlements) : ''}
            <WarningPopup isOpen={this.state.showWarningPopup} toggle={this.closeWarningPopup} message="YOU_ARE_NOT_AUTHORIZED" />
            {this.state.isAuthorized ?
              <AlertDataContext.Consumer>
                {({ alertData, setData }) => (

                  <div className="create-alert  text-capitalize fs-16 mr-1">
                    <div className="d-flex w-100 page-header">
                      <div className="pl-3 my-4 d-flex flex-grow-1">
                        <FormattedMessage id={(this.state.editID === -1) ? "CREATE_ALERT" : "EDIT_ALERT"} defaultMessage="create/edit alert" />
                      </div>
                    </div>
                    <hr />
                    <div>
                      <Formik
                        ref={this.formik}
                        render={props => (
                          <Form   {...props} isDataSaved={this.state.isSubmitting}
                            periodicValidation={this.state.periodicValidation} periodicalErrors={this.state.periodicalErrors}
                            setDates={this.setDates}
                          />
                        )}
                        initialValues={this.state.values}
                        validationSchema={validationSchema}
                        onSubmit={this.submitValues}
                      />
                    </div>
                  </div>
                )}
              </AlertDataContext.Consumer> : ''}
          </div>
        )}
      </EntitlementsContext.Consumer>
    );
  }
}

createAlert.contextType = AlertDataContext;

export default createAlert;
