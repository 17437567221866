import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './communication-possible-disable.scss';

export default class CommunicationPossibleDisable extends Component {
  render() {
    return (
      <div className="communication-possible-disable">
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="communication-possible-disable-modal" centered>
          <ModalHeader toggle={this.props.toggle}>
          <FormattedMessage id="KILL_COMMUNICATION" /></ModalHeader>
          <ModalBody>
            <FormattedMessage id={this.props.message} />
          </ModalBody>
          <ModalFooter className="d-flex flex-wrap">
            <Button color="cancel" onClick={this.props.toggle} size="modal">
              <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
            </Button>
            <Button color="primary" onClick={this.props.disableCommunication} disabled={this.props.isDeleting} size="modal">
                <FormattedMessage id="CONFIRM" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
