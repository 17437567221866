
import DE_FLAG from './../assets/images/Flag_of_Germany.png';
import EN_FLAG from './../assets/images/Flag_of_the_United_States.png';

export const ICON_PATH = "./../../assets/icons/";

export const LANGUAGES = {
  EN: {
    code: 'en',
    image: EN_FLAG
  },
  DE: {
    code: 'de',
    image: DE_FLAG
  },
};

export const MORE_ACTIONS = {
  edit: 'edit',
  duplicate: 'duplicate'
}

export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATE_TIME_FORMAT_BACKEND = 'YYYY.MM.DD HH:mm';

export const ALERT_TYPE = {
  information: { value: 'i', label: 'information' },
  maintenance: { value: 'm', label: 'Maintenance'},
};

export const WEEKDAYS = [
  { value: 'monday', label: 'monday', dayIndex: 1},
  { value: 'tuesday', label: 'tuesday', dayIndex: 2 },
  { value: 'wednesday', label: 'wednesday', dayIndex: 3 },
  { value: 'thursday', label: 'thursday', dayIndex: 4 },
  { value: 'friday', label: 'friday', dayIndex: 5 },
  { value: 'saturday', label: 'saturday', dayIndex: 6 },
  { value: 'sunday', label: 'sunday', dayIndex: 0 },
]
export const ALLOWED_TEXT = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*\s*$/;