import axios from 'axios';
import {
  loadProgressBar
} from 'axios-progress-bar';
import promise from 'promise';
import {
  toast
} from 'react-toastify';
import * as openIdConfig from './../constants/open-id-config';
import { logout } from './logout';

// Add a request interceptor 
var axiosInstance = axios.create();
const queryString = require('query-string');
let c = queryString.parse(window.location.search).code;
axiosInstance.defaults.headers.common = {};
axiosInstance.interceptors.request.use(function (config) {
  if (true) {

    if (config.url.endsWith('prompt=login')) {
      // config.headers = {'Content-Type': 'application/x-www-form-urlencoded'};
      // delete config.headers.Authorization;
      // axios.defaults.headers.common = {

      // }      

      // delete axios.defaults.headers.common["Authorization"];
    }
    if (config.type === 'auth') {
      config.url = `${config.url}?grant_type=${openIdConfig.grant_type}&code=${c}&redirect_uri=${openIdConfig.redirect_uri}`
      config.headers.Authorization = 'Basic ' + openIdConfig.client_id_base64;
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    } else if (config.type === 'userInfo') {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token')
    }
    else if (config.type !== 'logout') {
      config.headers.Authorization = localStorage.getItem('jwt_token')
    }
    if (config.method === 'get' && config.type !== 'logout') {
      // fix for ie cache issue 
      config.url = `${config.url}?t=${+ new Date()}`
    }
  }
  return config;
}, function (error) {
  console.log(error)
  // Do something with request error 
  return promise.reject(error);
});
axiosInstance.interceptors.response.use((response) => {
  return (response)
}, error => {
  console.log(error)
  if (!error.response) {
    toast.error("NETWORK ERROR");
    return Promise.reject(error)
    // return (error)
  } else {
    if (error.response.data && error.config.type !== 'logout') {
      toast.error(`Error: ${error.response.data.message}`);
      error = {
        ...error,
        isError: true
      }
      // return (error)
    } else {
      if (error.config.type !== 'logout')
        toast.error(`Error Code: ${error.response.status}`);
    }
    //if unauthorised, call logout
    if (error.response.status === 401) {
      logout();
    }
  }
  return Promise.reject(error)
})

loadProgressBar({
  minimum: 0.5,
  showSpinner: false,
  easing: 'ease',
  speed: 500
}, axiosInstance);
export default axiosInstance;