import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";

import ApiPath from "../../constants/api-path";
import axiosInstance from "../../utlities/interceptor";
import EntitlementsContext from '../../contexts/entitlements-context';
import WarningPopup from '../../components/warning-popup/warning-popup';
import { entitlements } from  '../../constants/entitlements';
import "./testsuite.scss";

// this page is visible only to users with entitlement TestSuiteAccess

export default class TestSuite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request: '',
      response: '',
      isSubmitted: false,
      isValid: true,
      isUploaded: false,
      showWarningPopup: false,
      onLoad: true,
      isAuthorized: false
    }
  }
  componentDidMount() {
    this.findAuth();
  }
  componentDidUpdate() {
    this.findAuth();
  }
  saveReq = (event) => {
    this.setState({ request: event.target.value, isUploaded: false })
  }

  submitXml = () => {
    this.setState({ isSubmitting: true, response: '' })
    let fd = { 'xml_data': this.state.request };
    axiosInstance.post(ApiPath.xmlRequest, fd).then(res => {
      this.setState({ isSubmitting: false })
      this.setState({ status: res.status, response: (res.data) })
    })
      .catch(error => {
        this.setState({
          isSubmitting: false,
          popupActive: false,
        });
      });
  }
  uploadFile = (event) => {
    this.setState({ request: '', response: '' })
    const input = event.target;
    this.setState({ isUploaded: true })
    if ('files' in input && input.files.length > 0) {
      if ((!input.files[0].name.endsWith(".xml")) && (!input.files[0].name.endsWith(".xsd"))) {
        this.setState({ isValid: false })
        return false
      }
      this.placeFileContent(input.files[0])
    }
  }

  placeFileContent = (file) => {
    this.setState({ isUploaded: true })
    this.readFileContent(file).then(content => {
      if (file.name.endsWith(".xml") || file.name.endsWith(".xsd")) {
        this.setState({ isValid: true })
      }
      this.setState({ request: content })
    }).catch(error => console.log(error))
  }

  readFileContent = (file) => {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onload = event => resolve(event.target.result)
      reader.onerror = error => reject(error)
      reader.readAsText(file)
    })
  }
  downloadResponse = () => {
    var FileSaver = require('file-saver');
    var blob = new Blob([this.state.response], { type: "'application/xml'" });
    FileSaver.saveAs(blob, "response.xsd");
  }
  closeWarningPopup = () => {
    this.setState({ showWarningPopup: false });
    this.props.history.push("/list")
  }
  setWarning() {
    this.setState({ showWarningPopup: true })
  }
  findAuth = () => {
    if (this.context && this.context.entitlements.length > 0 && this.state.onLoad) {
      if (!this.context.entitlements.includes(entitlements['apiValidator'])) {
        this.setState({ showWarningPopup: true, onLoad: false, isAuthorized: false })
      }
      else {
        this.setState({isAuthorized: true, onLoad: false})
      }
    }
  }
  render() {
    return (
      <div>
        <WarningPopup isOpen={this.state.showWarningPopup} toggle={this.closeWarningPopup} message="YOU_ARE_NOT_AUTHORIZED" />
        {this.state.isAuthorized ? <div className="testsuite-container">
          <div className="mb-4 d-flex mt-4 w-100 pl-3 heading-text-div">
            <span className="heading text-capitalize">
              <FormattedMessage id="API_VALIDATOR" defaultMessage="API validator" />
            </span>
          </div>
          <hr />
          <div className="w-100 pl-3 last-row-sticky">
            <span className="fs-12 mr-5">
              <span>
                <label for="myFile" className="btn btn-primary text-capitalize">
                  <FormattedMessage id="UPLOAD_XML"></FormattedMessage>

                </label>
                <input type="file" name="myFile" id="myFile" className="file-upload" onChange={(e) => this.uploadFile(e)} />
              </span>
              <Button id="Popover-type" type="button" className="popover-btn upload-btn">
                <img
                  src={require("../../assets/icons/Information_2.svg")}
                  alt="question mark"
                />
              </Button>
              <UncontrolledPopover
                placement="right"
                target="Popover-type"
                trigger="hover"
              >
                <PopoverBody className="popover-body">
                  <FormattedMessage id="SUPPORTS_ONLY_XML" />
                </PopoverBody>
              </UncontrolledPopover>
            </span>
            {!this.state.isValid ? <div className="errors-text fs-12 text-transform-initial">
              <FormattedMessage id="SUPPORTS_ONLY_XML" />
            </div> : ''}
            <div className="request-response">
              <textarea id="xml-request" name="xml-request" label="xml-request" className="textbox" onChange={this.saveReq}
                value={this.state.request} spellCheck="false"></textarea>
            </div>
            <button className="btn btn-primary text-capitalize submit" onClick={this.submitXml}
              disabled={!this.state.request.length}
              title={!this.state.isValid && this.state.isUploaded ? 'Upload XML file' : ''}>
              <FormattedMessage id={this.state.isSubmitting ? 'SUBMITTINGXML' : 'SUBMITXML'}></FormattedMessage>
            </button>
            <div className="request-response">
              <textarea id="xml-response" value={this.state.response} readOnly={true} spellCheck="false"></textarea>
            </div>
            {this.state.response ? <button className="btn btn-primary text-capitalize download" onClick={this.downloadResponse}>Download response</button>
              : null}
          </div>
        </div>
          : ''}
      </div>
    )
  }
}
TestSuite.contextType = EntitlementsContext;


