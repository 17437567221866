import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import { HeaderContext } from '../index';
import { AlertDataContext } from '../contexts/alert-data-context';
import List from '../pages/List/List';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import CreateAlert from '../pages/createAlert/createAlert';
import Systems from '../pages/systems/systems';
import Category from '../pages/category/category';
import FunctionCategory from '../pages/function-category/function-category';
import SystemFunctionMapping from '../pages/systemFunctionMapping/systemFunctionMapping';
import TestSuite from '../pages/testsuite/testsuite.jsx';
import PrivateRoute from './private-routes.jsx';
import Login from '../pages/login/login'
import Home from '../pages/home/home';

export default class routing extends Component {
  constructor(props) {
    super(props);
    this.setData = (data) => {
      this.setState(state => ({
        alertData: data
      }));
    }    
    this.state = {
      alertData: {},
      userInfo: '',
      setData: this.setData,
    };
  }

  render() {
    return (
      <HeaderContext.Consumer>
        {({ isMenuOpen }) => (
          <div className={isMenuOpen ? 'side-nav-margin' : 'side-nav-close-margin'}>
            <AlertDataContext.Provider value={this.state}>
              <Switch>
                {/* <React.StrictMode> */}
                <PrivateRoute exact path='/list' component={List} />
                <PrivateRoute exact path='/alert' component={CreateAlert} />
                <PrivateRoute exact path='/systems' component={Systems} />
                <PrivateRoute exact path='/category' component={Category} />
                <PrivateRoute exact path='/function_categories' component={FunctionCategory} />
                <PrivateRoute exact path='/mapping' component={SystemFunctionMapping} />
                <PrivateRoute exact path='/api-validator' component={TestSuite} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/home" component={Home} />
                <Redirect exact path='/' to='/login' />
                <PrivateRoute component={PageNotFound} />
                {/* </React.StrictMode> */}
              </Switch>
            </AlertDataContext.Provider>
          </div>
        )}
      </HeaderContext.Consumer>
    )
  }
}
